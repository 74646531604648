<template>
	<div v-if="!loading" class="flex flex-row flex-wrap justify-start items-start gap-1 w-full px-6 py-12">
		<!-- Назад -->
		<a class="flex h-6 mb-9 text-sm font-semibold text-blue-500 blue-arrow cursor-pointer" @click.prevent="$router.go(-1)"> Назад </a>
		<template v-if="!this.userIsInRoles(['MZ_Employee'])">
			<div class="hidden sm:flex w-full text-lg text-gray-900 font-bold">Автор анкеты: {{ getInfoAnket.userFullName }}</div>
			<div class="flex sm:hidden w-full text-lg text-gray-900 font-bold">
				Автор анкеты: <br />
				{{ getInfoAnket.userFullName }}
			</div>
			<div class="md:flex md:flex-row md:gap-4">
				<!-- Автор -->
				<div class="flex flex-col bg-white round-box w-full mb-4 md:mb-0 md:w-1/3 h-auto">
					<p class="mb-3 text-md text-gray-400 font-bold">Оценка создана: {{ getInfoAnket.created ? new Date(getInfoAnket.created).toLocaleString() : '' }}</p>
					<p class="text-lg text-gray-900 font-bold">
						{{ getInfoAnket.medicalOrgName }}
					</p>
				</div>
				<!-- Показатель -->
				<div class="flex flex-col bg-white round-box w-full mb-4 md:mb-0 md:w-1/3 h-auto">
					<p class="mb-3 text-md text-gray-400 font-bold">Показатель {{ getInfoAnket.numberBlock }}</p>
					<p class="text-sm text-gray-900">
						{{ getInfoAnket.titleBlock }}
					</p>
				</div>
				<!-- Поля -->
				<div class="flex flex-col justify-between bg-white round-box w-full mb-4 md:mb-0 md:w-1/6 h-auto">
					<p class="flex mb-5 text-md text-gray-400 font-bold">Заполнено полей:</p>
					<p class="text-3xl text-gray-900 mt-auto mb-5">
						{{ getInfoAnket.completedQuestions }}
						<span class="text-2xl text-gray-400 font-bold">из {{ getInfoAnket.totalQuestions }} </span>
					</p>
					<span class="progress-bar">
						<span
							class="progress-tracker"
							:class="{
								'bg-red-500': calcDoneMOPercent(getInfoAnket.completedQuestions, getInfoAnket.totalQuestions) < 50,
								'bg-yellow-400':
									calcDoneMOPercent(getInfoAnket.completedQuestions, getInfoAnket.totalQuestions) >= 50 &&
									calcDoneMOPercent(getInfoAnket.completedQuestions, getInfoAnket.totalQuestions) < 80,
								'bg-green-500': calcDoneMOPercent(getInfoAnket.completedQuestions, getInfoAnket.totalQuestions) >= 80
							}"
							:style="calcProgressBarWidth(calcDoneMOPercent(getInfoAnket.completedQuestions, getInfoAnket.totalQuestions))"
							>{{
						}}</span>
					</span>
				</div>
				<!-- Итог -->
				<div class="flex flex-col justify-between bg-blue-500 round-box w-full mb-4 md:mb-0 md:w-1/6 h-auto text-white">
					<p class="flex mb-5 text-md font-bold">Итого баллов по п. {{ getInfoAnket.numberBlock }}:</p>
					<p class="flex text-8xl font-bold">{{ getInfoAnket.result ? getInfoAnket.result : 0 }}</p>
				</div>
			</div>
		</template>

		<!-- Блоки -->
		<Form />

		<btn-arrow-up v-show="height > 500" @click="toTop" class="fixed bottom-12 right-6 cursor-pointer" />
	</div>
</template>

<script>
// Локальные данные стора
import { actions, getters, methods } from '@/store/store';
import Form from './Form.vue';
import btnArrowUp from '../../../../assets/img/arrow_up.svg';

export default {
	name: 'Main',
	components: { Form, btnArrowUp },
	computed: {
		...getters
	},
	data: () => ({
		loading: true,
		height: 0
	}),
	methods: {
		...actions,
		...methods,
		calcDoneMOPercent(a, b) {
			return Math.floor((a / b) * 100) || '0';
		},
		calcProgressBarWidth(percent) {
			return `width: ${percent}%`;
		},
		handleScroll() {
			this.height = window.scrollY;
		},
		toTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	async created() {
		if (this.userIsInRoles(['MZ_Employee'])) {
			await this.getAnketa(this.$route.params.anketaResultId).then((res) => {
				console.log(res);
				if (res.status === 200) this.loading = false;
			});
		} else {
			await this.getAnketaWithBlocks(this.$route.params.anketaResultId, this.$route.params.order).then((res) => {
				if (res.status === 200) this.loading = false;
			});
		}
	}
};
</script>

<style scoped>
.blue-arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-blue-500;
	content: '';
	transform: rotate(45deg);
}

.round-box {
	@apply flex px-10 py-8 rounded-lg filter drop-shadow-default;
}

.progress-bar {
	@apply flex flex-grow w-full h-2 max-h-2 rounded relative bg-gray-200;
	z-index: 10;
}

.progress-tracker {
	@apply flex h-2 rounded;
	width: 20%;
	z-index: 20;
}
</style>
