<template>
	<div class="flex flex-col flex-grow w-full">
		<ul class="flex flex-col w-full mt-4 text-sm text-gray-600 rounded-lg bg-white filter shadow-default overflow-hidden" v-for="(el, key) in getBlock" :key="key">
			<li class="questions-row bg-blue-500 text-white">
				<!-- <p>Вопрос</p> -->
				<p class="w-full font-bold text-white">
					{{ el.comment }}
				</p>
			</li>
			<Row
				v-for="(item, key) in el.questions"
				:key="key"
				:item="item"
				:indexNumber="item.numberTitle || key + 1"
				:edite="$route.query.edite ? JSON.parse($route.query.edite) : false"
				class="last-no-border"
			/>
		</ul>
	</div>
</template>

<script>
import { getters } from '@/store/store';
import Row from './Row.vue';

export default {
	name: 'Form',
	components: {
		Row
	},
	computed: {
		...getters
	}
};
</script>

<style>
.questions-row {
	/* @apply flex w-full px-7 py-5 border-t border-gray-100; */
	@apply flex w-full px-7 py-5;
}

.questions-row:nth-child(2) {
	@apply font-semibold;
}

.questions-row:nth-child(3) {
	@apply bg-gray-100;
}

.questions-answer {
	@apply flex;
}
</style>
