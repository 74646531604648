<template>
	<ul class="w-full border-t border-gray-100;">
		<li class="questions-row">
			<div class="w-3/5" :class="{ 'pl-6': !item.isParent }">{{ indexNumber }}. {{ item.title }}</div>

			<!-- Редактируем -->
			<div class="flex flex-wrap content-center ml-auto w-1/4" v-if="edite">
				<div v-for="(it, key) in item.answers" :key="'A' + key">
					<!-- RADION BTN -->
					<span v-if="it.type === 3">
						<!-- Да/Нет -->
						<span v-if="item.answers.length === 1">
							<label class="inline-flex items-center cursor-pointer">
								<input
									type="radio"
									class="w-5 h-5"
									:name="'accountType' + it.id"
									v-model="it.value"
									value="true"
									@change="inlineSave(it.anketaQuestionId, it.id, it.value, getInfoAnket.medicalId, getInfoAnket.anketaId, $route.params.anketaResultId)"
								/>
								<span class="ml-2">Да</span>
							</label>
							&nbsp;
							<label class="inline-flex items-center mt-0 ml-6 cursor-pointer">
								<input
									type="radio"
									class="w-5 h-5"
									:name="'accountType' + it.id"
									v-model="it.value"
									value="false"
									@change="inlineSave(it.anketaQuestionId, it.id, it.value, getInfoAnket.medicalId, getInfoAnket.anketaId, $route.params.anketaResultId)"
								/>
								<span class="ml-2">Нет</span>
							</label>
						</span>
						<!-- Остальное -->
						<span v-else>
							<label v-for="(i, ind) in it.answers" :key="'B' + ind" class="inline-flex items-center ml-6 cursor-pointer">
								<input type="radio" class="w-5 h-5" :name="'accountType' + it.id" :value="it.value" />
								<span class="ml-2">
									{{ i.title }}
								</span>
							</label>
						</span>
					</span>

					<!-- NUMBER -->
					<span v-if="it.type === 2">
						<input
							type="number"
							class="focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full sm:w-1/4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
							:class="{
								'border-red-500 focus-visible:border-red-500 focus-visible:ring-red-500 dark:focus-visible:ring-red-500 dark:focus-visible:border-red-500':
									it.value < 0 || it.value > 10,
								'border-blue-500 focus-visible:border-blue-500 focus-visible:ring-blue-500 dark:focus-visible:ring-blue-500 dark:focus-visible:border-blue-500':
									it.value > 0 && it.value <= 10
							}"
							v-model="it.value"
							v-filter="'[0-9.]'"
							@input="saveDebounceTN(it.anketaQuestionId, it.id, it.value, getInfoAnket.medicalId, getInfoAnket.anketaId, $route.params.anketaResultId, 'number')"
						/>
					</span>

					<!-- TEXT -->
					<span v-if="it.type === 1">
						<input
							type="text"
							class="focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full sm:w-1/4 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							v-model="it.value"
							@input="saveDebounceTN(it.anketaQuestionId, it.id, it.value, getInfoAnket.medicalId, getInfoAnket.anketaId, $route.params.anketaResultId, 'string')"
						/>
					</span>
				</div>
			</div>

			<!-- Показываем результат -->
			<div class="flex flex-wrap content-center ml-auto w-1/4" v-else>
				<div v-for="(it, key) in item.answers" :key="'A' + key">
					<!-- RADION BTN -->
					<span v-if="it.type === 3">
						<!-- Да/Нет -->
						<span class="items-center" v-if="item.answers.length === 1">
							{{ item.answers[0].value ? 'Да' : item.answers[0].value === false ? 'Нет' : '' }}
						</span>
						<!-- Остальное -->
						<span v-else> ... </span>
					</span>

					<!-- TEXT || NUMBER -->
					<span v-if="it.type === 1 || it.type === 2">
						{{ item.answers[0].value }}
					</span>
				</div>
			</div>
		</li>
	</ul>
</template>

<script>
import debounce from 'lodash.debounce';
import { actions, getters } from '@/store/store';

export default {
	name: 'Row',
	props: {
		item: {
			type: Object,
			required: true
		},
		indexNumber: {
			type: Number,
			required: true
		},
		edite: {
			type: Boolean,
			required: true
		}
	},
	computed: {
		...getters
	},
	methods: {
		...actions,
		saveDebounceTN: debounce(function (anketaQuestionId, anketaAnswerId, value, medicalId, anketaId, anketaResultId, type) {
			if (type === 'number' && (value < 0 || value > 10)) {
				this.$toast.warning('Можно вводить числа в диапазоне от 0 до 10', {
					position: 'top-right',
					timeout: 3000,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: true,
					closeButton: 'button',
					icon: true,
					rtl: false
				});

				return;
			}
			this.inlineSave(anketaQuestionId, anketaAnswerId, value, medicalId, anketaId, anketaResultId);
		}, 1000),
		inlineSave(anketaQuestionId, anketaAnswerId, value, medicalId, anketaId, anketaResultId) {
			this.$http
				.post('/Anketa/PostAnswer', {
					anketaQuestionId,
					anketaAnswerId,
					value,
					medicalId,
					anketaId,
					anketaResultId
				})
				.then((res) => {
					if (res.status === 200) {
						this.getAnketaWithBlocks(this.$route.params.anketaResultId, this.$route.params.order);
						this.$toast.success('Ответ успешно сохранен!', {
							position: 'top-right',
							timeout: 2000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: 'button',
							icon: true,
							rtl: false
						});
					}
				})
				.catch((error) => {
					if (error) {
						this.$toast.error('Это анкета для другого пользователя!', {
							position: 'top-right',
							timeout: 10000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: true,
							closeButton: 'button',
							icon: true,
							rtl: false
						});
					}
				});
		}
	}
};
</script>
